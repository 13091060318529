import { MdClose } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import countries from '../../utils/countries';

export default function Sidebar({ isOpen, setClose, links, userData }) {
  const { pathname } = useLocation();

  return (
    <>
      {isOpen && (
        <div
          className='w-screen h-screen fixed top-0 left-0 z-10 bg-[#00000080] duration-300 backdrop-blur-sm'
          onClick={setClose}
        ></div>
      )}
      <div
        className={`block bg-white fixed min-h-screen top-0 left-0 z-20 
        w-72 duration-300 ${isOpen ? 'translate-x-0' : '-translate-x-[100%]'}`}
      >
        <button
          aria-label='Sidebar toggle Button'
          onClick={setClose}
          className={`p-2 absolute -right-4 top-16 bg-white z-20 border border-gray-100 rounded-md text-gray-800 ${
            isOpen ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <MdClose />
        </button>
        <div className='p-4'>
          {pathname.includes('/dashboard') && (
            <>
              <p className=''>Account Number:</p>
              <p className='font-bold text-xl'>{userData.account_number}</p>
            </>
          )}
          <p className='mt-4'>{userData.fullname}</p>
          <p className='text-gray-700 text-sm font-semibold'>
            {userData.email}
          </p>
        </div>
        {pathname.includes('/dashboard') && (
          <div className='bg-secondary p-4 mt-2'>
            <p className='text-gray-100 text-sm font-semibold'>Balance</p>
            <p className='text-gray-100 font-extrabold text-3xl mt-2'>
              {countries
                .find(
                  (country) =>
                    country.name.toLowerCase() ===
                    userData?.country?.toLowerCase()
                )
                .code.toUpperCase()}{' '}
              {userData.balance.toLocaleString()}.00
            </p>
          </div>
        )}
        <div className='mt-8'>
          {links.map((link) => (
            <Link
              key={link.name}
              to={`${link.page}`}
              className='flex items-center my-4 mx-0 text-sm'
              onClick={setClose}
            >
              <div
                className={`w-1 h-10 duration-300 ${
                  pathname.trim() === link.page ? 'bg-secondary' : 'white'
                }`}
              />
              <div
                className={`py-4 flex gap-4 w-full h-10 items-center duration-300 justify-start pl-6 ${
                  pathname.trim() === link.page
                    ? 'bg-green-100 text-secondary font-bold'
                    : 'white text-gray-800'
                }`}
              >
                <span className='text-lg'>{link.icon}</span>
                <span className={`capitalize`}>{link.name}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
