import emailjs from '@emailjs/browser';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase.config';
import countries from '../utils/countries';

const Signup = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [formdata, setFormdata] = useState({
    email: '',
    phone: '',
    password: '',
    fullname: '',
    username: '',
    country: 'Afghanistan',
  });

  const { fullname, username, email, country, password, phone } = formdata;

  const inputChangeHandler = (e) => {
    setFormdata((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const validateFormInputs = () => {
    return (
      fullname.trim().length > 0 &&
      username.trim().length > 0 &&
      country.trim().length > 0 &&
      email.trim().length > 0 &&
      phone.trim().length >= 10 &&
      password.trim().length >= 6
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateFormInputs()) {
      delete formdata?.password;

      try {
        setIsLoading(true);

        const usersRef = collection(db, 'users');
        const users = await getDocs(usersRef);

        const usersLength = users.docs.length;

        const date = new Date();

        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        const day = date.getDate();

        const account_number = `${year}${month >= 10 ? month : `0${month}`}${
          day >= 10 ? day : `0${day}`
        }${usersLength >= 10 ? usersLength : `0${usersLength}`}`;

        const userRef = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        const data = {
          ...formdata,
          _id: userRef.user.uid.trim(),
          access: password,
          gender: '',
          income: 0,
          balance: 0,
          capital: 0,
          savings: 0,
          expenses: 0,
          refundable_balance: 0,
          deposits: [],
          withdrawals: [],
          isAdmin: false,
          isVerified: false,
          account_number,
          timestamp: new Date(),
          withdrawal_code: '',
        };

        const ref = doc(db, 'users', userRef.user.uid);
        await setDoc(ref, data);

        setIsLoading(false);
        toast.success(`Welcome`);
        navigate('/dashboard');

        await emailjs
          .send(
            'service_g0jgrtw',
            'template_ygsrbqm',
            {
              subject: 'Welcome to Infinite Finance!',
              receiver: fullname,
              message1: `
              Welcome to Infinite Finance! We are thrilled to have you on board as a valued member of our banking family. Your trust means the world to us, and we are committed to providing you with exceptional service and a seamless banking experience.`,
              message2: `
              You can access your account online through our secure Banking portal. Simply visit https://infinitefinance.online and use your account credentials to log in. Online banking allows you to check your balance, view transactions, transfer funds, and much more, all from the comfort of your home.
              `,
              to_email: email,
            },
            'CDbQ0enNBqu4x8OvS'
          )
          .then((res) => console.log(res));
      } catch (error) {
        console.log(error);
        setIsLoading(false);

        // toast.error('Email or password is invalid');
      }
    } else {
      if (password.trim().length < 6) {
        toast.error('Password must be at least 6 digits');
      } else {
        toast.error('One or more inputs are invalid');
      }
    }
  };

  return (
    <>
      <section className='p-4 py-16 min-h-screen bg-gray-900 flex flex-col gap-8 items-center justify-center'>
        <Link
          to='/'
          className='font-extrabold text-gray-100 text-xl text-center'
        >
          Infinite Finance
        </Link>
        <div className='bg-white shadow-md rounded-md p-6 w-full max-w-lg'>
          <div className='mb-4'>
            <p className='text-gray-700 font-extrabold text-2xl'>
              Create an Account
            </p>
            <p className='text-gray-700 text-sm'>
              Sign up with your email and get started with your free account.
            </p>
          </div>
          <form className='gap-6 py-4' onSubmit={handleSubmit}>
            <div className='w-full mb-4'>
              <label
                htmlFor='fullname'
                className='mb-2 font-semibold text-sm text-gray-700'
              >
                Full Name *
              </label>
              <input
                type='text'
                id='fullname'
                placeholder=''
                className='px-4 py-3 outline-none border border-gray-100 w-full rounded-md text-gray-700 bg-gray-50'
                required
                onChange={inputChangeHandler}
              />
            </div>
            <div className='w-full mb-4'>
              <label
                htmlFor='username'
                className='mb-2 font-semibold text-sm text-gray-700'
              >
                Username *
              </label>
              <input
                type='text'
                id='username'
                placeholder=''
                className='px-4 py-3 outline-none border border-gray-100 w-full rounded-md text-gray-700 bg-gray-50'
                required
                onChange={inputChangeHandler}
              />
            </div>
            <div className='w-full mb-4'>
              <label
                htmlFor='email'
                className='mb-2 font-semibold text-sm text-gray-700'
              >
                Email Address *
              </label>
              <input
                type='email'
                id='email'
                name='email'
                placeholder=''
                className='px-4 py-3 outline-none border border-gray-100 w-full rounded-md text-gray-700 bg-gray-50'
                required
                onChange={inputChangeHandler}
              />
            </div>
            <div className='w-full mb-4'>
              <label
                htmlFor='phone'
                className='mb-2 font-semibold text-sm text-gray-700'
              >
                Phone *
              </label>
              <input
                type='tel'
                id='phone'
                name='phone'
                placeholder=''
                className='px-4 py-3 outline-none border border-gray-100 w-full rounded-md text-gray-700 bg-gray-50'
                required
                onChange={inputChangeHandler}
              />
            </div>
            <div className='w-full mb-4'>
              <label
                htmlFor='country'
                className='mb-2 font-semibold text-sm text-gray-700'
              >
                Country *
              </label>
              <select
                id='country'
                name='country'
                className='px-4 py-3 outline-none border border-gray-100 w-full rounded-md text-gray-700 bg-gray-50'
                required
                onChange={inputChangeHandler}
              >
                {countries.map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='w-full mb-4'>
              <label
                htmlFor='password'
                className='mb-2 font-semibold text-sm text-gray-700'
              >
                Password *
              </label>
              <input
                type='password'
                id='password'
                name='password'
                placeholder=''
                className='px-4 py-3 outline-none border border-gray-100 w-full rounded-md text-gray-700 bg-gray-50'
                required
                onChange={inputChangeHandler}
              />
            </div>

            <button
              type='submit'
              className={`btn mt-8 ${
                isLoading ? 'bg-gray-600' : 'bg-primary hover:bg-secondary'
              } text-center mt-8 py-4 text-white  w-full`}
            >
              {isLoading ? 'Loading...' : 'Register'}
            </button>
          </form>

          <p className='mt-4 text-gray-700 text-center text-sm'>
            Already have an account?{' '}
            <Link to='/login' className='text-primary font-semibold'>
              Sign in instead
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};

export default Signup;
