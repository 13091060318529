import React from 'react';
import { Link } from 'react-router-dom';
import parseDate from '../../utils/parseDate';

const UsersTable = ({ users }) => {
  return (
    <div className='mt-8 flex flex-col'>
      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          <div className='overflow-hidden border-b border-gray-300'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead className='bg-gray-400'>
                <tr className='text-sm text-gray-900 uppercase'>
                  <th
                    scope='col'
                    className='px-6 py-4 text-left tracking-wider font-bold'
                  >
                    Full Name
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-4 text-left tracking-wider font-bold'
                  >
                    Email
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-4 text-left tracking-wider font-bold'
                  >
                    Date Joined
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-4 text-right tracking-wider font-bold'
                  ></th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-300 text-sm'>
                {users
                  ?.sort(
                    (a, b) => b?.timestamp?.seconds - a?.timestamp?.seconds
                  )
                  .map((user, i) => (
                    <tr
                      key={user._id}
                      className={`${
                        i % 2 === 0 ? 'bg-gray-200' : 'bg-gray-300'
                      }`}
                    >
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {user.fullname}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {user.email}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {parseDate(
                          user.timestamp ? user.timestamp?.seconds * 1000 : 0
                        )}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-right'>
                        <Link
                          to={`/super-admin/users/${user?._id}`}
                          className='p-2 px-6 font-semibold text-primary text-sm border border-primary rounded-sm'
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
