import { v4 as uuidv4 } from 'uuid';

const FAQs = [
  {
    ID: uuidv4(),
    question: 'How can I apply for a loan?',
    answer:
      'To apply, you simply contact us by email then we direct you to our agents, or contact any of our agents on WhatsApp through the links we will provide in our Contact Page.',
  },
  {
    ID: uuidv4(),
    question: 'How do I apply for a grant?',
    answer:
      'We announce whenever we are hosting a grant, we give away money to facilitate small businesses and private infrastructure. All you have to do is wait till whenever you are hosting a grant. Our grants are only available to 12 countries',
  },
  {
    ID: uuidv4(),
    question: 'How long must I wait to get paid?',
    answer:
      'After your registeration, verification and approval, you must wait for us to authenticate with your bank, this takes any time from 7 to 21 days.',
  },

  {
    ID: uuidv4(),
    question: 'How much money can I request for?',
    answer:
      'All verified users can apply for as low as $500 to $100,000. The rates are 4%, 8%, 10%, 12% and 15% respectively for every 20K range',
  },
  {
    ID: uuidv4(),
    question: 'What documents do you require?',
    answer:
      "This depends on the country of the applicant. Typically, we need your ID Card, Voter's card or SSN, Proof of Residence, Proof of Employment or Bank Statement dated 3 months prior, (International Passport is a big plus)",
  },
  {
    ID: uuidv4(),
    question: 'Do you need access to my bank details?',
    answer:
      "Approved users must give us access to their bank account, but not diretly. We have a system that registers users. Users must approve our system and authenticate us with their bank before any deposit is made, this makes sure we have a strong hold on the user and can file for monthly deductions in the future incase the user refuses to comply as initially agreed. The time difference between application and authentication varies depending on the user's country but it takes anywhere from 7 to 21 days.",
  },
];

export default FAQs;
