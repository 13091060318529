import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase.config';

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formdata, setFormdata] = useState({ email: '', password: '' });

  const { email, password } = formdata;

  const inputChangeHandler = (e) => {
    setFormdata((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const loginHandler = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const res = await signInWithEmailAndPassword(auth, email, password);

      if (res.user) {
        const uid = res.user.uid;

        const __res = await getDoc(doc(db, 'users', uid));
        const isAdmin = __res.data().isAdmin;

        console.log('Is Admin: ', isAdmin);

        setIsLoading(false);

        if (isAdmin) {
          navigate('/super-admin');
        }
      }
    } catch (error) {
      toast.error('Invalid email or password');

      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className='padding py-16 min-h-screen bg-gray-900 flex flex-col gap-8 items-center justify-center'>
        <Link
          to='/'
          className='font-extrabold text-gray-100 text-xl text-center'
        >
          Infinite Finance
        </Link>
        <div className='bg-white shadow-md rounded-md p-6 w-full max-w-lg'>
          <div className='mb-4'>
            <p className='text-gray-700 font-extrabold text-2xl'>
              Admin Login Portal
            </p>
          </div>
          <form className='gap-6 py-4' onSubmit={loginHandler}>
            <div className='w-full mb-4'>
              <label
                htmlFor='email'
                className='mb-2 font-semibold text-sm text-gray-700'
              >
                Email Address *
              </label>
              <input
                type='email'
                id='email'
                name='email'
                placeholder=''
                className='px-4 py-3 outline-none border border-gray-100 w-full rounded-md text-gray-700 bg-gray-50'
                required
                value={email}
                onChange={inputChangeHandler}
              />
            </div>
            <div className='w-full mb-4'>
              <label
                htmlFor='password'
                className='mb-2 font-semibold text-sm text-gray-700'
              >
                Password *
              </label>
              <input
                type='password'
                id='password'
                name='password'
                placeholder=''
                className='px-4 py-3 outline-none border border-gray-100 w-full rounded-md text-gray-700 bg-gray-50'
                required
                value={password}
                onChange={inputChangeHandler}
              />
            </div>

            <button
              type='submit'
              className={`btn ${
                isLoading ? 'bg-gray-600' : 'bg-primary hover:bg-secondary'
              } text-center mt-8 py-4 text-white  w-full`}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Login'}
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Login;
