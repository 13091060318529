import React from 'react';
import { useOutletContext } from 'react-router-dom';
import countries from '../../utils/countries';

const stats = [
  { name: 'income' },
  { name: 'savings' },
  { name: 'refundable_balance' },
  { name: 'expenses' },
];

const StatCards = () => {
  const { userData } = useOutletContext();

  return (
    <div className='mx-auto w-full max-w-4xl grid grid-cols-1 sm:grid-cols-2 gap-4'>
      {stats.map((stat) => (
        <div key={stat.name} className='p-6 bg-white rounded-md shadow-sm'>
          <p className='text-sm font-bold text-gray-500 uppercase mb-4'>
            {stat.name}
          </p>
          <p className='text-3xl font-bold text-gray-800'>
            {countries
              .find(
                (country) =>
                  country.name.toLowerCase() ===
                  userData?.country?.toLowerCase()
              )
              .code.toUpperCase()}
            {userData[stat.name]}.00
          </p>
        </div>
      ))}
    </div>
  );
};

export default StatCards;
