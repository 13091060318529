import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className='hero py-32 h-screen flex items-center justify-center flex-col bg-gray-900 gap-4 p-4 sm:p-8 md:p-16 lg:px-32'>
      <h1 className='mt-8 text-center font-extrabold text-gray-50 text-3xl  md:text-4xl xl:text-5xl'>
        Award Winning, Leading Financial Unicorn
      </h1>
      <p className='text-center text-gray-50 text-lg xl:text-xl'>
        We prepare you for financial independence, powering your financial
        future. <br /> Thousands of customers trust Tsunami Bank for their
        business and personal needs. Your financial journey starts here.
      </p>

      <Link to='/register' className='mt-4 btn btn-primary'>
        Create Account
      </Link>
    </section>
  );
};

export default Hero;
