import React from 'react';
import { Footer, Navbar } from '../components';
import About from '../components/About';
import Hero from '../components/Hero';
import HowItWorks from '../components/HowItWorks';
import Services from '../components/Services';

const Home = () => {
  return (
    <>
      <Navbar />
      <main>
        <Hero />
        <Services />
        <HowItWorks />
        <About />
      </main>
      <Footer />
    </>
  );
};

export default Home;
