import React from 'react';
import { useOutletContext } from 'react-router-dom';
import TransactionHistory from '../../components/Dashboard/TransactionHistory';

const Transactions = () => {
  const { userData } = useOutletContext();

  return (
    <div className='mx-auto max-w-4xl p-4 py-32'>
      <p className='text-xl font-bold'>Transaction History</p>
      <TransactionHistory
        transactions={[...userData.deposits, ...userData.withdrawals]}
      />
    </div>
  );
};

export default Transactions;
