import React, { useEffect, useState } from 'react';
import StatCards from '../../components/Admin/StatCards';
import UsersTable from '../../components/Admin/UsersTable';
import AdminLayout from '../../layouts/AdminLayout';
import { AdminService } from '../../services/admin';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const _users = await AdminService.fetchAllUsers();

        setUsers(_users);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className='padding py-32'>
      <StatCards />

      <div className='mt-16'>
        <p className='text-2xl font-extrabold '>Users</p>

        <UsersTable users={users} />
      </div>
    </div>
  );
};

export default AdminDashboard;
