import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BsStack } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import about1 from '../../assets/about-1.jpg';

const About = () => {
  return (
    <div>
      <section className='p-4 py-8 sm:p-8 md:p-16 xl:px-32 grid grid-cols-1 lg:grid-cols-2 gap-8'>
        <img src={about1} className='min-h-[512px] mx-auto' />
        <div className='p-4'>
          <p className='text-primary font-bold'>About Infinite Finance</p>
          <p className='text-gray-800 font-extrabold my-2 text-2xl sm:text-3xl lg:text-4xl'>
            Our story. Who we are. What we do.
          </p>
          <p className='text-gray-700 leading-8'>
            We are a financial institution working towards revolutionalizing
            online banking worldwide. We offer services that satisfies your
            personal and business needs. We are an international business
            working with the goal of helping build small businesses and private
            infrastructure. We provide quality financial consultation services,
            offer soft money loans with small interest rates, and empowerment
            grants.
          </p>
          <Link
            to='/register'
            className='mt-4 btn border border-primary flex items-center gap-4 max-w-xs'
          >
            Get Started <AiOutlineArrowRight />
          </Link>
        </div>
      </section>
      <section className='p-4 py-8 sm:p-8 md:p-16 xl:px-32 bg-gray-100'>
        <div className='flex flex-col items-center justify-center p-4 gap-4 mb-12'>
          <BsStack className='text-6xl text-primary' />
          <h2 className='text-center text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-extrabold text-gray-700'>
            Personal Banking Now Available
          </h2>
          <p className='font-medium lg:text-lg text-center'>
            Thousands of customer worldwide trust Infinite Finance for their
            business and personal needs. Start banking with us today.
          </p>
          <Link to='/' className='btn btn-primary px-16 w-full max-w-sm'>
            Join Us Now
          </Link>
        </div>
      </section>
    </div>
  );
};

export default About;
