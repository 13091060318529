import emailjs from '@emailjs/browser';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { BiSolidBank } from 'react-icons/bi';
import { MdOutlineContentCopy } from 'react-icons/md';
import { SiHiveBlockchain } from 'react-icons/si';
import { useOutletContext } from 'react-router-dom';
import qr_code from '../../assets/qr_code.jpeg';
import TransactionHistory from '../../components/Dashboard/TransactionHistory';
import Modal from '../../components/Modal';
import { UserService } from '../../services/user';
import parseDate from '../../utils/parseDate';

const Deposit = () => {
  const { userData } = useOutletContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [method, setMethod] = useState('');
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const wallet = ['34hr3YoJ9Tq9CKfsPDgiQhbqtAq5Cd5sb5'];

  const bank = ['010101222'];

  const prices = {
    btc: 37088.1,
    eth: 1500,
    usdt: 600,
  };

  const createNewDeposit = async () => {
    const data = {
      type: 'deposit',
      amount: +amount,
      method,
      date: new Date(),
      status: 'pending',
    };

    if (+amount > 0) {
      try {
        setIsLoading(true);

        if (data)
          await UserService.sendDepositRequest(userData._id, data).then(() => {
            toast.success('Deposit placed.');
            setModalOpen(false);

            setAmount('');
            setMethod('');
          });

        await emailjs
          .send(
            'service_g0jgrtw',
            'template_ygsrbqm',
            {
              subject: 'New User Deposit!',
              receiver: '',
              message1: `A deposit of $${amount} has been placed by ${userData.fullname}`,
              message2: `
                            User Details:
                            Name: ${userData.fullname}
                            Email: ${userData.email}

                            Transaction Details:
                            Status: 'pending'
                            Amount: $${amount}
                            Date: ${parseDate(new Date().getTime())}
              `,
              to_email: 'admin@infinitefinance.online',
            },
            'CDbQ0enNBqu4x8OvS'
          )
          .then((res) => console.log(res));

        setIsLoading(false);
      } catch (error) {
        console.log(error);

        setIsLoading(false);
        toast.error('Error! Cannot place deposit ');
      }
    } else {
      toast.error('Amount must be more than $0');
    }
  };

  return (
    <>
      {userData && (
        <div className='mx-auto max-w-4xl p-4 py-32'>
          <div className='mb-16 grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <button
              type='button'
              className='w-full p-6 pb-16 rounded-3xl bg-primary text-gray-50 relative'
              onClick={() => {
                setModalOpen(true);
                setMethod('Crypto');
              }}
            >
              <p className='text-2xl font-extrabold'>Fund Via Crypto</p>
              <span className='absolute bottom-6 right-6 text-3xl'>
                <SiHiveBlockchain />
              </span>
            </button>
            <button
              type='button'
              className='w-full p-6 pb-16 rounded-3xl bg-primary text-gray-50 relative'
              onClick={() => {
                // setModalOpen(true);
                // setMethod('Bank');
                toast.success('Contact support for payment via local bank.');
              }}
            >
              <p className='text-2xl font-extrabold'>Fund Via Local Bank</p>
              <span className='absolute bottom-6 right-6 text-3xl'>
                <BiSolidBank />
              </span>
            </button>
          </div>

          <div className='mt-16'>
            <p className='text-xl font-bold'>Deposit History</p>

            <TransactionHistory transactions={[...userData.deposits]} />
          </div>

          <Modal
            open={modalOpen}
            heading={'Fund account via ' + method}
            closeModal={() => setModalOpen(false)}
          >
            <div className='text-gray-700'>
              {method.toLocaleLowerCase() !== 'bank' && (
                <>
                  <p className='mb-4 text-sm'>
                    Please send{' '}
                    <span className='font-bold'>
                      ${+amount.toLocaleString()}{' '}
                    </span>
                    to the address below. The balance will appear in your
                    account after it is confirmed by our team.
                  </p>
                  <p className='font-bold'>
                    Scan the QR code or copy the address
                  </p>
                </>
              )}

              <div className='mt-4'>
                {method.toLocaleLowerCase() !== 'bank' && (
                  <div className='h-28 w-28 mx-auto rounded-md my-4 border border-gray-500 '>
                    <img
                      src={qr_code}
                      alt={' qr code'}
                      className='rounded-lg w-full h-full bg-gray-700 border object-contain'
                    />
                  </div>
                )}

                <div className='my-6'>
                  <label
                    htmlFor='method'
                    className='font-semibold text-sm text-gray-700 mb-2'
                  >
                    Amount (USD)
                    {/* Amount ({method.toUpperCase()}) */}
                  </label>
                  <input
                    type='number'
                    name='method'
                    id='method'
                    className='w-full px-4 py-2 outline-none rounded-md bg-gray-200 text-gray-700 text-sm'
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>

                <div className=''>
                  <p className='mb-4 text-sm'>
                    Send Amount:{' '}
                    {method.toLocaleLowerCase() !== 'bank' && (
                      <>
                        ${+amount.toLocaleString()}{' '}
                        <span className='font-bold text-base'>
                          ({(+amount / prices.btc).toLocaleString()} BTC)
                        </span>
                      </>
                    )}{' '}
                    {method.toLocaleLowerCase() === 'bank' && (
                      <span className='font-bold'>${amount} </span>
                    )}
                  </p>
                  <div className='flex items-center justify-between border border-gray-400 bg-gray-300 p-3 rounded-md'>
                    <p className='text-sm text-gray-700'>
                      {method.toLocaleLowerCase() === 'bank' ? bank : wallet}
                    </p>

                    <button
                      title='Copy button'
                      className='text-primary'
                      onClick={() => {
                        window.navigator.clipboard.writeText(wallet);
                        alert('Copied address');
                      }}
                    >
                      <MdOutlineContentCopy className='text-lg' />
                    </button>
                  </div>
                  {method.toLocaleLowerCase() === 'bank' && (
                    <div className='mt-4'>
                      <p>
                        Bank:
                        <span className='font-bold'>Flex Financial Bank</span>
                      </p>
                      <p>
                        Account Name:
                        <span className='font-bold'>Flex Finance Inc.</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='mt-8 flex justify-end'>
              <button
                type='button'
                className='btn bg-primary text-gray-100 hover:bg-secondary rounded-md py-3 px-12'
                onClick={createNewDeposit}
              >
                {isLoading ? 'Loading...' : 'I HAVE PAID'}
              </button>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Deposit;
