import React from 'react';
import { BsFillCreditCardFill } from 'react-icons/bs';
import { GiWallet } from 'react-icons/gi';
import { PiUsersLight } from 'react-icons/pi';

const stats = [
  {
    icon: <PiUsersLight />,
    title: 'Active Users',
    value: '43K',
    color: 'text-purple-600',
    background: 'bg-purple-100',
  },

  {
    icon: <GiWallet />,
    title: 'Avg. Monthly Deposits',
    value: '20.1M',
    color: 'text-green-500',
    background: 'bg-green-100',
  },
  {
    icon: <BsFillCreditCardFill />,
    title: 'Avg. Monthly Withdrawals',
    value: '11.3M',
    color: 'text-red-500',
    background: 'bg-red-100',
  },
];

const Stats = () => {
  return (
    <section className='p-4 py-16 md:p-16 xl:px-32 bg-white'>
      <p className='mb-8 text-center text-3xl font-extrabold text-gray-800 md:text-4xl'>
        Our Open <span className='text-primary'>Statistics</span>
      </p>

      <div className='mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-16'>
        {stats.map((stat) => (
          <div
            key={stat.title}
            className='flex flex-col items-center justify-center gap-2'
          >
            <span
              className={`h-20 w-20 flex items-center justify-center text-4xl p-2 rounded-full ${stat.background} ${stat.color}`}
            >
              {stat.icon}
            </span>
            <p className='text-center text-3xl font-extrabold text-gray-800 md:text-4xl'>
              {stat.value}
            </p>
            <p className='capitalize font-medium text-lg'>{stat.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Stats;
