import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db, storage } from '../firebase.config';

export const AdminService = {
  fetchAllUsers: async function () {
    const q = query(collection(db, 'users'), where('isAdmin', '==', false));

    const __users = [];

    const userSnapshot = await getDocs(q);
    userSnapshot.forEach((doc) => {
      __users.push(doc.data());
    });

    return __users;
  },
  fetchAllWithdrawals: async function () {
    const q = query(
      collection(db, 'withdrawalRequests'),
      where('status', '==', 'pending')
    );

    const __withdrawals = [];

    const userSnapshot = await getDocs(q);
    userSnapshot.forEach((doc) => {
      __withdrawals.push(doc.data());
    });

    return __withdrawals;
  },
  fetchAllDeposits: async function () {
    const q = query(
      collection(db, 'depositRequests'),
      where('status', '==', 'pending')
    );

    const __deposits = [];

    const userSnapshot = await getDocs(q);
    userSnapshot.forEach((doc) => {
      __deposits.push(doc.data());
    });

    return __deposits;
  },
};
