import React from 'react';
import { BsRocket, BsShieldFillCheck } from 'react-icons/bs';
import { FaPeopleCarry } from 'react-icons/fa';

import { AiOutlineArrowRight } from 'react-icons/ai';
import { Ri24HoursFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const services = [
  {
    icon: <Ri24HoursFill />,
    title: '24/7 Online Banking',
    content:
      'We provide you with 24/7 functional banking, this helps you run a reliable freelance business and takes your growth to the next level.',
  },
  {
    icon: <BsShieldFillCheck />,
    title: 'Secure Transactions',
    content:
      'We provide you with 24/7 functional banking, this helps you run a reliable freelance business and takes your growth to the next level.',
  },
  {
    icon: <FaPeopleCarry />,
    title: 'Personal Financial Advice',
    content:
      'We provide you with 24/7 functional banking, this helps you run a reliable freelance business and takes your growth to the next level.',
  },
];

const Services = () => {
  return (
    <div>
      <section className='p-4 py-8 sm:p-8 md:p-16 xl:px-32'>
        <div className='flex flex-col items-center justify-center p-4 gap-4 mb-12'>
          <BsRocket className='text-6xl text-primary' />
          <h2 className='text-center text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-extrabold text-gray-700'>
            Choose Your Product
          </h2>
          <p className='font-medium lg:text-lg text-center'>
            Enjoy several benefits and explore the awesome services we have to
            offer.
          </p>
        </div>
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
          {services.map((service) => (
            <div
              key={service.title}
              className='flex flex-col items-center justify-center gap-4 p-6 py-16 border border-gray-200 text-gray-800 mx-auto max-w-sm'
            >
              <span className='text-primary text-5xl'>{service.icon}</span>
              <p className='text-center font-bold text-lg'>{service.title}</p>
              <p className='text-center text-gray-500'>{service.content}</p>
            </div>
          ))}
        </div>
      </section>
      <div className='service-1 md:pr-24 lg:pr-32'>
        <div className='p-4 py-8 sm:p-8 md:p-16 xl:px-32 gap-4'>
          <p className='mt-16 font-extrabold text-xl sm:text-2xl md:text-3xl text-gray-50'>
            Bank At Your Convenience
          </p>

          <p className='text-gray-100 my-4'>
            We understand that your time is valuable, and that's why we offer a
            range of flexible and accessible banking solutions tailored to your
            needs.
          </p>
          <Link to='/register' className='btn btn-primary'>
            Start Banking <AiOutlineArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Services;
