import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../components/Dashboard/Navbar';
import Sidebar from '../components/Dashboard/Sidebar';
import Spinner from '../components/Dashboard/Spinner';
import { auth, db } from '../firebase.config';
import { admin_links } from '../utils/links';

const AdminLayout = () => {
  const isMounted = useRef(true);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsAuthorized(true);

          const userRef = doc(db, 'users', user.uid);

          onSnapshot(userRef, (snap) => {
            if (snap.exists()) {
              setUserData(snap.data());

              if (snap.data().isAdmin) return;
              else navigate('/dashboard');
            }
          });
        }
        setCheckingStatus(false);
      });
    }

    return () => {
      isMounted.current = false;
    };
  }, [auth]);

  return (
    <>
      {checkingStatus && !isAuthorized && <Spinner />}
      {!checkingStatus && !isAuthorized && <Navigate to='/' />}
      {isAuthorized && !checkingStatus && userData && (
        <div className='bg-gray-100 min-h-screen'>
          <Navbar setOpen={() => setSidebarOpen(true)} />
          <Sidebar
            isOpen={sidebarOpen}
            setClose={() => setSidebarOpen(false)}
            links={admin_links}
            userData={userData}
          />

          <Outlet context={{ userData }} />
        </div>
      )}
    </>
  );
};

export default AdminLayout;
