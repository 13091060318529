import React from 'react';
import { BiLogoPaypal, BiSolidBank } from 'react-icons/bi';
import { BsCurrencyBitcoin } from 'react-icons/bs';
import { TbCircleLetterS } from 'react-icons/tb';
import { Link, useOutletContext } from 'react-router-dom';
import StatCards from '../../components/Dashboard/StatCards';
import TransactionHistory from '../../components/Dashboard/TransactionHistory';
import countries from '../../utils/countries';

const payment_methods = [
  {
    page: 'bank',
    name: 'Bank Withdrawal',
    icon: <BiSolidBank />,
    background: 'bg-secondary',
  },
  {
    page: 'bitcoin',
    name: 'bitcoin Withdrawal',
    icon: <BsCurrencyBitcoin />,
    background: 'bg-yellow-500',
  },
  {
    page: 'paypal',
    name: 'paypal Withdrawal',
    icon: <BiLogoPaypal />,
    background: 'bg-blue-600',
  },
  {
    page: 'skrill',
    name: 'skrill Withdrawal',
    icon: <TbCircleLetterS />,
    background: 'bg-purple-600',
  },
];

const Home = () => {
  const { userData } = useOutletContext();

  return (
    <>
      <div className='p-4 sm:px-8 lg:px-16 xl:px-24 py-16 bg-secondary h-40' />
      <div className='p-4 sm:px-8 lg:px-16 xl:px-24'>
        <div className='mx-auto max-w-4xl rounded-md text-gray-700 p-6 bg-white -mt-[60px] min-h-[240px] shadow-sm'>
          <div className='pb-4 border-b border-b-gray-200 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-8'>
            <div>
              <p className='text-xl font-bold'>
                Welcome,{' '}
                <span className='text-secondary font-extrabold capitalize'>
                  {userData.fullname}
                </span>
              </p>
              <p className='text-lg font-semibold'>Total Balance</p>
              <p className='text-3xl font-bold mt-4'>
                {countries
                  .find(
                    (country) =>
                      country.name.toLowerCase() ===
                      userData?.country?.toLowerCase()
                  )
                  .code.toUpperCase()}{' '}
                {userData?.balance?.toLocaleString()}.00
              </p>
            </div>
            <Link
              to='/dashboard/deposit'
              className='duration-500 flex items-center justify-center bg-gray-200 hover:bg-secondary hover:text-white h-full p-4 rounded-md text-5xl font-light w-1/3 sm:w-auto'
            >
              +
            </Link>
          </div>
          <div className='py-4 max-w-3xl mx-auto mt-4 grid grid-cols-2 sm:grid-cols-4 gap-6'>
            {payment_methods.map((method) => (
              <Link
                to={`/dashboard/withdraw?method=${method.page}`}
                key={method.name}
                className='flex flex-col items-center justify-center gap-4'
              >
                <span
                  className={`p-3 rounded-md text-2xl text-white ${method.background}`}
                >
                  {method.icon}
                </span>
                <strong className='text-[12px] capitalize'>
                  {method.name}
                </strong>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className='max-w-4xl mx-auto p-4 sm:px-8'>
        <StatCards />

        <div className='my-16'>
          <p className='text-xl font-bold'>Transaction History</p>

          <TransactionHistory
            transactions={[...userData.deposits, ...userData.withdrawals]}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
