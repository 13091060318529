import React from 'react';
import { About as AboutSection, Footer, Navbar } from '../components';
import Heading from '../components/About/Heading';
import Stats from '../components/About/Stats';

const About = () => {
  return (
    <>
      <Navbar />
      <Heading />
      <AboutSection />
      <Stats />
      <Footer />
    </>
  );
};

export default About;
