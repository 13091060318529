import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { user_links } from '../../utils/links';

const MobileNav = () => {
  const { pathname } = useLocation();

  return (
    <div className='sm:hidden fixed bottom-0 left-0 w-full bg-white p-4 shadow-xl border-t border-t-gray-100 flex items-center justify-around'>
      {user_links.map((link) => (
        <Link
          to={`${link.page}`}
          key={link.name}
          className='flex flex-col items-center justify-center'
        >
          <span
            className={`p-3 rounded-md text-lg ${
              pathname === link.page ? 'text-secondary' : 'text-gray-500'
            }`}
          >
            {link.icon}
          </span>
          <strong
            className={`text-[12px] capitalize ${
              pathname === link.page ? 'text-secondary' : 'text-gray-500'
            }`}
          >
            {link.name}
          </strong>
        </Link>
      ))}
    </div>
  );
};

export default MobileNav;
