import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../firebase.config';

export const UserService = {
  getUrlFromFileUpload: async function (_fileRef, userId, randomId, file) {
    const fileRef = ref(
      storage,
      `${_fileRef}/${userId}_${randomId}_${new Date().getTime()}`
    );

    const snapshot = await uploadBytes(fileRef, file);
    const fileUrl = await getDownloadURL(snapshot.ref);

    return fileUrl;
  },

  sendDepositRequest: async function (user, data) {
    const userRef = doc(db, 'users', user);
    const res = await getDoc(userRef);

    const deposits = res?.data()?.deposits;

    const _ = await addDoc(collection(db, 'depositRequests'), {
      ...data,
      user,
      status: 'pending',
      type: 'deposit',
    });

    await updateDoc(userRef, {
      deposits: [
        ...deposits,
        {
          ...data,
          status: 'pending',
          type: 'deposit',
          id: _.id,
        },
      ],
    });

    return { message: 'Successful', ok: true, id: data?._id };
  },

  sendWithdrawalRequest: async function (user, data) {
    const userRef = doc(db, 'users', user);
    const res = await getDoc(userRef);

    const withdrawals = res?.data()?.withdrawals;

    const _ = await addDoc(collection(db, 'withdrawalRequests'), {
      ...data,
      user,
      status: 'pending',
      type: 'withdraw',
    });

    await updateDoc(userRef, {
      withdrawals: [
        ...withdrawals,
        {
          ...data,
          status: 'pending',
          type: 'withdraw',
          id: _.id,
        },
      ],
    });

    return { message: 'Successful', ok: true, id: data?._id };
  },

  uploadUserAvatar: async function (userId, randomId, file) {
    const url = await this.getUrlFromFileUpload(
      'userAvatars',
      userId,
      randomId,
      file
    );

    return url;
  },
};
