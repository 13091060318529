import emailjs from '@emailjs/browser';
import { ArrowBackIosRounded, Check } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Footer, Navbar } from '../../components';
import countries from '../../data/Countries';
import { db } from '../../firebase.config';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--accent)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--accent)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: 'var(--accent)',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: 'var(--accent)',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
}

const steps = ['Underwriting', 'Personal'];

const Loan = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    purpose: '',
    paybackDuration: '',
    gender: 'Male',
    loanDebt: 'YES',
    loanCompany: 'YES',
    staff: 'Temporary',
    occupation: '',
    jobDuration: '',
    monthlyDeduction: '',
    salaryBank: '',
    paymentDate: '',
    earningPeriod: 'Monthly',
    fullName: '',
    idNumber: '',
    adress: '',
    nationality: 'South Africa',
    workplace: '',
    phone: '',
    bank: '',
    loanAmount: '',
    currency: 'ZA',
    netPay: '',
  });

  const {
    fullName,
    email,
    purpose,
    paybackDuration,
    loanDebt,
    loanCompany,
    staff,
    occupation,
    jobDuration,
    monthlyDeduction,
    salaryBank,
    paymentDate,
    earningPeriod,
    gender,
    idNumber,
    address,
    nationality,
    workplace,
    phone,
    bank,
    loanAmount,
    currency,
    netPay,
  } = userData;

  const goBackward = () => setActiveStep((prev) => prev - 1);
  const goForward = () => setActiveStep((prev) => prev + 1);

  const formInputHandler = (e) => {
    setUserData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value.trim(),
    }));
  };

  const submitApplication = async () => {
    try {
      toast.loading('Sending...');
      const docRef = await addDoc(collection(db, 'users'), {
        ...userData,
        timestamp: serverTimestamp(),
      });

      console.log('Document written with ID: ', docRef.id);

      setTimeout(() => {
        navigate('/application/success');
      }, 500);
    } catch (e) {
      console.error('Error adding document: ', e);
    }

    emailjs
      .send(
        'service_tdi00ub',
        'template_zw29g6d',
        {
          ...userData,
        },
        'QuX4s80cdNzEpFBny'
      )
      .then(() => {
        emailjs.send(
          'service_tdi00ub',
          'template_0wakb5d',
          {
            to_name: fullName,
            message:
              'Congratulations for passing this application stage. Our agent will contact you for the next stages.',
            to_email: email,
          },
          'QuX4s80cdNzEpFBny'
        );
      })
      .then(() => {
        toast.success(
          'Application Successful. You will receive an email shortly.'
        );
      });
  };

  return (
    <>
      <main className='w-screen bg-gray-50 pt-24 pb-24 px-4 md:px-24 lg:px-32'>
        <>
          {activeStep <= 2 && (
            <Box mx='auto'>
              <Typography
                component='h1'
                variant='h4'
                sx={{
                  textAlign: 'center',
                  pt: 4,
                  pb: 2,
                  fontFamily: 'inherit',
                  fontWeight: 600,
                }}
              >
                Loan Application
              </Typography>

              <Stack sx={{ width: '100%' }} spacing={4} py={2}>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<QontoConnector />}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>
              <form className='application-form'>
                {activeStep === 0 && (
                  <>
                    <Typography
                      pb={2}
                      pt={2}
                      sx={{ fontFamily: 'inherit', fontWeight: 500 }}
                    >
                      Let us know more about you
                    </Typography>
                    <TextField
                      id='purpose'
                      label='What is your purpose for our company loan?'
                      defaultValue={purpose}
                      onChange={formInputHandler}
                      required
                    />
                    <TextField
                      id='paybackDuration'
                      label='How long will be your payback duration?'
                      defaultValue={paybackDuration}
                      onChange={formInputHandler}
                      required
                    />
                    <FormControl fullWidth>
                      <InputLabel
                        variant='standard'
                        htmlFor='uncontrolled-native'
                      >
                        Have you applied for a loan in any company asides us?
                      </InputLabel>
                      <NativeSelect
                        defaultValue={loanCompany}
                        inputProps={{
                          name: 'loanCompany',
                          id: 'loanCompany',
                        }}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            loanCompany: e.target.value,
                          }))
                        }
                      >
                        <option value={'YES'}>YES</option>
                        <option value={'NO'}>NO</option>
                      </NativeSelect>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel
                        variant='standard'
                        htmlFor='uncontrolled-native'
                      >
                        Are you currently under financial debt in any company,
                        or financial body?
                      </InputLabel>
                      <NativeSelect
                        defaultValue={loanDebt}
                        inputProps={{
                          name: 'loanDebt',
                          id: 'loanDebt',
                        }}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            loanDebt: e.target.value,
                          }))
                        }
                      >
                        <option value={'YES'}>YES</option>
                        <option value={'NO'}>NO</option>
                      </NativeSelect>
                    </FormControl>
                    <TextField
                      id='occupation'
                      label='What is your occupation or type of Job you do?'
                      defaultValue={occupation}
                      onChange={formInputHandler}
                      required
                    />
                    <FormControl fullWidth>
                      <InputLabel variant='standard' htmlFor='staff'>
                        Are you a permanent or temporary staff?
                      </InputLabel>
                      <NativeSelect
                        defaultValue={staff}
                        inputProps={{
                          name: 'staff',
                          id: 'staff',
                        }}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            staff: e.target.value,
                          }))
                        }
                      >
                        <option value={'Temporary'}>Temporary</option>
                        <option value={'Permanent'}>Permanent</option>
                      </NativeSelect>
                    </FormControl>
                    <TextField
                      id='jobDuration'
                      label='How long have you been working your current job?'
                      defaultValue={jobDuration}
                      onChange={formInputHandler}
                      required
                    />
                    <TextField
                      id='monthlyDeduction'
                      label='When do you want us to start monthly deduction?'
                      defaultValue={monthlyDeduction}
                      onChange={formInputHandler}
                      required
                    />
                    <TextField
                      id='salaryBank'
                      label='What is the bank you earn monthly income with?'
                      defaultValue={salaryBank}
                      onChange={formInputHandler}
                      required
                    />
                    <Typography mt={2} sx={{ fontWeight: 600 }}>
                      Next Payday
                    </Typography>
                    <TextField
                      type='date'
                      id='paymentDate'
                      onChange={formInputHandler}
                      required
                    />
                    <FormControl fullWidth>
                      <InputLabel variant='standard' htmlFor='staff'>
                        Specify your earning period (Monthly / Weekly / Daily)
                      </InputLabel>
                      <NativeSelect
                        defaultValue={earningPeriod}
                        inputProps={{
                          name: 'earningPeriod',
                          id: 'earningPeriod',
                        }}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            earningPeriod: e.target.value,
                          }))
                        }
                      >
                        <option value={'Daily'}>Daily</option>
                        <option value={'Weekly'}>Weekly</option>
                        <option value={'Monthly'}>Monthly</option>
                      </NativeSelect>
                    </FormControl>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <Typography
                      pb={2}
                      pt={2}
                      sx={{ fontFamily: 'inherit', fontWeight: 500 }}
                    >
                      Personal Information (Fill all fields before submitting)
                    </Typography>
                    <TextField
                      id='fullName'
                      label='Full Name'
                      defaultValue={fullName}
                      onChange={formInputHandler}
                      required
                    />
                    <TextField
                      id='email'
                      label='Email'
                      type='email'
                      defaultValue={email}
                      onChange={formInputHandler}
                      required
                    />
                    <FormControl fullWidth>
                      <InputLabel variant='standard' htmlFor='staff'>
                        Gender
                      </InputLabel>
                      <NativeSelect
                        defaultValue={gender}
                        inputProps={{
                          name: 'gender',
                          id: 'gender',
                        }}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            gender: e.target.value,
                          }))
                        }
                      >
                        <option value={'Male'}>Male</option>
                        <option value={'Female'}>Female</option>
                        <option value={'Other'}>Other</option>
                      </NativeSelect>
                    </FormControl>
                    <TextField
                      id='idNumber'
                      label='Identity / Passport Number'
                      defaultValue={idNumber}
                      onChange={formInputHandler}
                      required
                    />
                    <TextField
                      id='address'
                      type='address'
                      label='Residential Address'
                      defaultValue={address}
                      onChange={formInputHandler}
                      required
                    />
                    <FormControl fullWidth>
                      <InputLabel variant='standard' htmlFor='staff'>
                        Nationality
                      </InputLabel>
                      <NativeSelect
                        defaultValue={nationality}
                        inputProps={{
                          name: 'nationality',
                          id: 'nationality',
                        }}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            nationality: e.target.value,
                          }))
                        }
                      >
                        {countries.map((country, index) => (
                          <option
                            value={country.name}
                            key={`${index}-${country.code}`}
                          >
                            {country.name}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                    <TextField
                      id='workplace'
                      label='Workplace / Company'
                      defaultValue={workplace}
                      onChange={formInputHandler}
                      required
                    />
                    <TextField
                      id='phone'
                      type='phone'
                      label='Phone Number / Cellphone Number'
                      defaultValue={phone}
                      onChange={formInputHandler}
                      required
                    />
                    <TextField
                      id='bank'
                      label='Bank Name'
                      defaultValue={bank}
                      onChange={formInputHandler}
                      required
                    />
                    <TextField
                      id='netPay'
                      type='number'
                      label='Net Pay'
                      defaultValue={netPay}
                      onChange={formInputHandler}
                      required
                    />
                    <TextField
                      id='loanAmount'
                      type='number'
                      label='Requested Loan Amount'
                      defaultValue={loanAmount}
                      onChange={formInputHandler}
                      required
                    />
                    <FormControl fullWidth>
                      <InputLabel variant='standard' htmlFor='staff'>
                        Currency
                      </InputLabel>
                      <NativeSelect
                        defaultValue={currency}
                        inputProps={{
                          name: 'currency',
                          id: 'currency',
                        }}
                        onChange={(e) =>
                          setUserData((prev) => ({
                            ...prev,
                            currency: e.target.value,
                          }))
                        }
                      >
                        {countries.map((country, index) => (
                          <option
                            value={country.name}
                            key={`${index}-${country.code}`}
                          >
                            {country.code} - {country.name}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </>
                )}
                <Stack direction='row' alignItems='center' gap={1} mt={2}>
                  <Button
                    variant='outlined'
                    disableElevation
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      textTransform: 'capitalize',
                      fontSize: '1rem',
                      borderColor: 'var(--accent)',
                      color: 'var(--accent)',
                    }}
                    disabled={activeStep === 0}
                    onClick={goBackward}
                  >
                    <ArrowBackIosRounded />
                  </Button>
                  <Button
                    variant='contained'
                    disableElevation
                    sx={{
                      p: 2,
                      width: '100%',
                      borderRadius: 2,
                      background: 'var(--accent)',
                      textTransform: 'capitalize',
                      fontSize: '1rem',
                      '&:hover': {
                        background: 'var(--accent)',
                      },
                    }}
                    onClick={() => {
                      if (activeStep === 0) {
                        if (
                          purpose.trim().length > 0 &&
                          paybackDuration.trim().length > 0 &&
                          loanCompany.trim().length > 0 &&
                          loanDebt.trim().length > 0 &&
                          occupation.trim().length > 0 &&
                          staff.trim().length > 0 &&
                          jobDuration.trim().length > 0 &&
                          monthlyDeduction.trim().length > 0 &&
                          salaryBank.trim().length > 0 &&
                          earningPeriod.trim().length > 0 &&
                          paymentDate.trim().length > 0
                        ) {
                          goForward();
                        } else {
                          toast.error('Cannot submit. Provide all details');
                        }
                      } else if (activeStep === 1) {
                        if (
                          fullName.trim().length > 0 &&
                          email.trim().length > 0 &&
                          idNumber.trim().length > 0 &&
                          address.trim().length > 0 &&
                          nationality.trim().length > 0 &&
                          workplace.trim().length > 0 &&
                          phone.trim().length > 0 &&
                          bank.trim().length > 0 &&
                          parseInt(netPay) > 0 &&
                          parseInt(loanAmount) > 0 &&
                          currency.trim().length > 0 &&
                          gender.trim().length > 0
                        ) {
                          submitApplication();
                        } else {
                          toast.error('Provide all details');
                        }
                      }
                    }}
                  >
                    {activeStep > 0 ? 'Submit' : 'Next'}
                  </Button>
                </Stack>
              </form>
            </Box>
          )}
        </>
      </main>
    </>
  );
};

export default Loan;
