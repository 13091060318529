import { useState } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { Footer, Navbar } from '../components';
import questions from '../data/FAQs';

const CollapseCard = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const toggleShowAnswer = () => {
    setShowAnswer((prev) => !prev);
  };

  return (
    <div className='w-full mb-4'>
      <h1
        className={`text-lg font-bold cursor-pointer relative ${
          showAnswer ? 'mb-4' : 'mb-8'
        }`}
        onClick={toggleShowAnswer}
      >
        {question}
        {showAnswer ? (
          <IoMdArrowDropup className='absolute top-0 right-0 text-2xl' />
        ) : (
          <IoMdArrowDropdown className='absolute top-0 right-0 text-2xl' />
        )}
      </h1>
      {showAnswer && (
        <p className='font-medium  p-4 bg-gray-300 rounded-xl'>{answer}</p>
      )}
    </div>
  );
};

const FAQs = () => {
  return (
    <>
      <Navbar />
      <section className='header FAQ-page bg-gray-900 w-screen py-32 px-4 md:px-24 lg:px-48'>
        <h1 className='text-2xl text-center md:text-3xl font-bold mb-4 text-blue-600 relative'>
          FREQUENTLY ASKED QUESTIONS
        </h1>

        <div className='w-full bg-gray-200 p-8 m-auto relative rounded-xl'>
          {questions.map((question) => (
            <CollapseCard
              key={question.ID}
              question={question.question}
              answer={question.answer}
            />
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FAQs;
