import { signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { auth } from '../../firebase.config';
import countries from '../../utils/countries';

const data = {
  full_name: 'Johnny Doe',
  username: 'johndoe',
  email: 'johndoe@gmail.com',
  country: 'United States of America',
  currency: 'USD',
};

const Settings = () => {
  const { userData } = useOutletContext();

  const navigate = useNavigate();

  const logoutHandler = async () => {
    await signOut(auth);
    navigate('/');
  };

  return (
    <div className='mx-auto max-w-3xl p-4 py-32'>
      <div className='mb-4'>
        <p className='text-sm font-bold text-gray-500 mb-4'>
          Personal Information
        </p>
        <div className='bg-white p-4 rounded-md'>
          <div className='my-4 py-2 flex items-center justify-between text-sm text-gray-700 border-b border-b-gray-100'>
            <p>Full Name</p>
            <p className='font-semibold'>{userData.fullname}</p>
          </div>
          <div className='my-4 py-2 flex items-center justify-between text-sm text-gray-700 border-b border-b-gray-100'>
            <p>Username</p>
            <p className='font-semibold'>{userData.username}</p>
          </div>
          <div className='my-4 py-2 flex items-center justify-between text-sm text-gray-700 border-b border-b-gray-100'>
            <p>Email</p>
            <p className='font-semibold'>{userData.email}</p>
          </div>
          <div className='my-4 py-2 flex items-center justify-between text-sm text-gray-700 border-b border-b-gray-100'>
            <p>Country</p>
            <p className='font-semibold'>{userData.country}</p>
          </div>
          <div className='my-4 py-2 flex items-center justify-between text-sm text-gray-700 border-b border-b-gray-100'>
            <p>Currency</p>
            <p className='font-semibold'>
              {countries
                .find(
                  (country) =>
                    country.name.toLowerCase() ===
                    userData.country.toLowerCase()
                )
                .code.toUpperCase()}
            </p>
          </div>
        </div>
      </div>

      <div className='my-8'>
        <p className='text-sm font-bold text-gray-500 mb-4'>
          Password & Security
        </p>
        <div className='bg-white p-4 rounded-md'></div>
        <button
          className='p-4 mt-16 text-sm text-center w-full block rounded-lg bg-red-600 text-white font-bold hover:bg-red-700 duration-500'
          onClick={logoutHandler}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Settings;
