// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBfK3sy07AV2xkUIQivrWqaBU8_wQHXS_8',
  authDomain: 'tsunami-bank.firebaseapp.com',
  projectId: 'tsunami-bank',
  storageBucket: 'tsunami-bank.appspot.com',
  messagingSenderId: '133544390900',
  appId: '1:133544390900:web:b4eb88099538012da42ea1',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage();
