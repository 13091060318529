import React from 'react';

const Heading = () => {
  return (
    <section className='min-h-[512px] gradient p-8 py-32 md:px-16 xl:px-32 flex flex-col justify-center gap-4'>
      <p className='text-2xl font-extrabold text-gray-50 sm:text-3xl lg:text-4xl'>
        About Us
      </p>
      <p className='leading-8 text-gray-50 md:text-lg'>
        Infinite Finance is a world leading banking firm & decentralized
        financial establishment. We aim to revolutionise the way people bank,
        save, invest, and enhance investors financial education. Our services
        are structured to give you the best for your business and personal needs
        and we pride ourselves in delivering an exceptional banking experience.
        Below are our public stats of realtime data.
      </p>
    </section>
  );
};

export default Heading;
