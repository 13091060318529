import React from 'react';

const Spinner = () => {
  return (
    <div className='w-screen h-screen bg-white flex items-center justify-center'>
      Loading...
    </div>
  );
};

export default Spinner;
