import { AiFillHome, AiFillPlusCircle, AiFillSetting } from 'react-icons/ai';
import { BsBank2, BsFillArrowUpRightSquareFill } from 'react-icons/bs';
import { FaReceipt, FaUsers } from 'react-icons/fa';

export const user_links = [
  { name: 'home', page: '/dashboard', icon: <AiFillHome /> },
  { name: 'deposit', page: '/dashboard/deposit', icon: <AiFillPlusCircle /> },
  {
    name: 'withdraw',
    page: '/dashboard/withdraw',
    icon: <BsFillArrowUpRightSquareFill />,
  },
  {
    name: 'loan',
    page: '/dashboard/loan',
    icon: <BsBank2 />,
  },
  {
    name: 'transactions',
    page: '/dashboard/transactions',
    icon: <FaReceipt />,
  },
  {
    name: 'settings',
    page: '/dashboard/settings',
    icon: <AiFillSetting />,
  },
];

export const admin_links = [
  { name: 'home', page: '/super-admin', icon: <AiFillHome /> },
  { name: 'users', page: '/super-admin/users', icon: <FaUsers /> },
  {
    name: 'deposit requests',
    page: '/super-admin/deposits',
    icon: <AiFillPlusCircle />,
  },
  {
    name: 'withdraw requests',
    page: '/super-admin/withdrawals',
    icon: <BsFillArrowUpRightSquareFill />,
  },
];
