import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const Modal = ({ children, heading, open, showHeader = true, closeModal }) => {
  return (
    <>
      {open && (
        <div className='overlay p-4 z-50 fixed top-0 left-0 h-full w-full backdrop-blur-sm bg-[rgba(0,0,0,0.5)] flex items-center justify-center duration-500'>
          <div className='py-4 w-full max-w-lg rounded-md bg-gray-100 shadow-xl duration-500'>
            {showHeader && (
              <div className='p-4 px-6 border-b border-b-gray-200 flex items-center justify-between'>
                <p className='text-lg capitalize text-gray-700 font-extrabold'>
                  {heading}
                </p>
                <button
                  type='button'
                  title='Close Modal Button'
                  onClick={closeModal}
                >
                  <AiOutlineCloseCircle className='text-xl text-gray-700' />
                </button>
              </div>
            )}
            <div className='p-4 px-6'>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
